import React from "react";
import MainLayout from "./components/mainLayout/MainLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";

function App() {
  return (
    <>
      {/*<div className="gif-bg"></div>*/}
      <MainLayout />
    </>
  );
}

export default App;
