import Twitter from "../assets/icons/social1.png";
import Discord from "../assets/icons/social2.png";
import OpenSea from "../assets/icons/social3.png";
import Instagram from "../assets/icons/social4.png";

export const socialData = [
   {
     socialUrl: "https://twitter.com/iceapeclub",
     socialIcon: Twitter
    },
  {
    socialUrl: "https://discord.gg/pCwhDZxfYK",
    socialIcon: Discord,
  },
  // {
  //   socialUrl: "#",
  //   socialIcon: OpenSea
  // },
  {
    socialUrl: "https://www.instagram.com/iceapenft/",
    socialIcon: Instagram,
  },
];
